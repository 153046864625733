const RootPage = () => { 
    if (typeof window !== 'undefined') {
    window.location = '/fr'
    return ""
    } else {
        return ""
    }
}

export default RootPage
